

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.modal {
    text-align: center;
    flex-direction: column;
    position: fixed;
    top: 13vh;
    height: 50vh;
    left: 2.5%;
    width: 95%;
    z-index: 100;
    overflow: hidden;
    justify-content: center;
    align-items: center;
  }

.closeContainer {
  display: flex;
  justify-content: flex-end;
  width: 95%;
} 

.close {
  display: flex;
  flex-direction: row;
  justify-content: center;
  line-height: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  position: absolute;
  width: 30px;
}

.close:hover,
.close:focus {
  box-shadow: none;
  border-color: none;
  color: none;
  background: none;
}

.close h1 {
  font-size: 28px;
  margin-top: 12px;
  font-weight: normal;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0;
  padding: 0;
  align-items: center;
}

.modal h2 {
  line-height: 3vh;
  color: #49484D;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: normal;
}

.modal li {
  justify-content: center;
  border-bottom: 1px solid #D00D28;
  width: 100%;
  list-style-type: none;
}

.modal li:last-child {
  border-bottom: none;
}
  