.blurb-container {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 2rem;
}

.image {
    width: 50%;
    display: flex;
    justify-content: center;
    padding-top: 30px;
}

.image img {
    border-radius: 50%;
    align-self: flex-start;
    width: 90%;
}

.blurb-txt {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.heading {
    padding-top: 24px;
    margin-bottom: 0;
}

.paragraph {
    display: flex;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    color: #49484D;
    line-height: 7vh;
    font-size: 1.7vw;
    margin-right: 50px;
}

@media screen and (max-width: 768px) {

    .blurb-container {
        display: block;
        margin-right: 0;
        margin-left: 0;
    }

    .heading {
        text-align: center;
    }

    .blurb-txt {
       text-align: center;
       width: 100%;
    }

    .paragraph {
        margin-right: 5%;
        margin-left: 5%;
        font-size: 3vw;
    }
    .image {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .image img {
        border-radius: 12px;
        align-self: auto;
        width: 100%;
    }
}

@media screen and (max-width: 576px) {
    .blurb-container {
        display: block;
        margin: 0;
        text-align: center;
    }
    .blurb-txt {
        text-align: center;
        width: 100%;
     }
 
     .paragraph {
         margin-right: 5%;
         margin-left: 5%;
         font-size: 4vw;
         line-height: 8vw;
     }
     .image {
         position: relative;
         width: 100%;
         height: 100%;
         padding-left: 0;
         padding-right: 0;
         padding-top: 0;
     }
 
     .image img {
         border-radius: 12px;
         align-self: auto;
         width: 100%;
         height: 100%;
     }
    .firstName {
        color: white;
        position: absolute;
        top: 16px;
        right: 24px;
        font-size: 16vw;
        margin: 0;
    }

    .lastName {
        color: white;
        position: absolute;
        bottom: 8px;
        right: 24px;
        font-size: 16vw;
        margin: 0;
    }
}