.bigBox {
    display: flex;
    background-color: transparent;
    border-style: none;
    border-radius: 0;
    box-shadow: none;
    justify-content: center;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    max-width: 100%;
    height: auto;
    align-items: center;
    
}


@media screen and (max-width: 768px) {
  .bigBox {
    margin-left: 0;
    margin-right: 0;
    display: block;
  }
}