.container {
    display: flex;
    display: -webkit-flex;
    -webkit-justify-content: right;
    justify-content: flex-end;
    white-space: normal;
    align-items: center;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    background-color: white;
  }

.rightSide {
    display: -webkit-flex;
    display: flex;
    width: 50%;
    justify-content: right;
    flex-direction: column;
    -webkit-flex-direction: column;
    
}

.testimonial {
    border-radius: 50%;
    max-width: 30vw;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #49484D;
    font-style: italic; 
    position: absolute;
    right: 57vw;
    line-height: 30px;
}

.name {
    display: flex;
    justify-content: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #49484D;
    font-style: italic; 
    line-height: 30px;
}

.heading {
    margin-bottom: 0;
}

.instructionText {
    display: flex;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    color: #49484D;
    margin-right: 7%;
    font-size: 1.2rem;
    line-height: 30px;
}

.instButton {
    margin-bottom: 8%;
}

@media screen and (max-width: 768px) {
    .rightSide {
        width: 100%;
        justify-content: center;
    }

    .container {
        width: 90%;
        margin: auto;
        justify-content: center;
    }

    .instructionText {
        justify-content: center;
        text-align: center;
        margin: 5% 5%;
        font-size: 4vw;
        line-height: 8vw;
        
    }

    .heading {
        text-align: center;
    }

    .instButton {
        display: flex;
        justify-content: center;
    }
}
