.card {
    background: #FEFAF5;
    box-shadow: 0 4px 8px rgba(0, 0, 0.26);
    border-radius: 10px;
    height: auto;
    display: -webkit-flex;
    margin-bottom: 2rem;
}

@media screen and (max-width: 768px) {
    .card {
        width: 100%;
    }
}