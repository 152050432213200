.container {
    display: inline-block;
    cursor: pointer;
    margin-right: 5%;
  }
  
  .bar1, .bar2, .bar3 {
    width: 30px;
    height: 5px;
    background-color: #49484D;
    margin: 4px 0;
    transition: 0.4s;
  }
  
  /* Rotate first bar */
  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-6px, 6px) ;
    transform: rotate(-45deg) translate(-6px, 6px) ;
  }
  
  /* Fade out the second bar */
  .change .bar2 {
    opacity: 0;
  }
  
  /* Rotate last bar */
  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-5px, -8px) ;
    transform: rotate(45deg) translate(-5px, -8px) ;
  }