.container {
    display: flex;
    justify-content: center;
    white-space: normal;
}

.music {
  display: block;
  margin-top: 10rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  text-align: center;
  border-radius: 12px;
}

.heading {
  margin: 0 auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.leftTile {
  margin-right: 3%;
}

@media screen and (max-width: 768px) {

  .container {
    width: 100%;
    display: block;
    justify-content: center;
    white-space: normal;
  }

  .music {
    width: 90%;
    margin: 5% auto;
    padding-bottom: 2%;
  }
}
