.headerContainer {
    display: flex;
    justify-content: space-evenly;
}

.logo {
        
        display: flex;
        width: 100%;
        height: auto;
        margin-bottom: 10%;
        align-items: center;
    
  
}

.logo-container {
    width: 30%;
    margin-top: 5%;
}

.icons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 3%;
    flex: 1;
}

.hamburger {
    display: flex;
    flex: 1;
}

.facebook {
    display: flex;
    color: #426782;
    margin-right: 3vw;
}

.youtube {
    display: flex;
    color: red;
    margin-right: 3vw;
}

.patreon {
    color: #f96854;
}

.menu {
    display: flex;
    justify-content: center;
    font-family: Futura;
    font-weight: bold;
    font-size: large;
    color: #49484D;
    list-style-type: none;
}

.menu li {
    margin-right: 7%;
}

.pages:visited {
    color: inherit;
}

.pages:hover {
    color: lightslategrey;
}

.fhole {
    max-width: 0.9%;
    height: auto;
    position: absolute;
    left: 340px;
    top: 182px;
}

.fhole-right {
    max-width: 0.9%;
    height: auto;
    position: absolute;
    left: 428px;
    top: 181px;
    transform: scaleX(-1);
}

@media screen and (max-width: 768px) {
    .headerContainer {
        justify-content: center;
    }

    .icons {
        visibility: hidden;
    }
   .logo-container {
       width: 50%;
   }
}

@media screen and (max-width: 576px) {
    .hamburger {
        justify-content: flex-end;
        align-items: center;

    }

      /* Rotate first bar */
  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-6px, 6px) ;
    transform: rotate(-45deg) translate(-6px, 6px) ;
  }
  
  /* Fade out the second bar */
  .change .bar2 {
    opacity: 0;
  }
  
  /* Rotate last bar */
  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-5px, -8px) ;
    transform: rotate(45deg) translate(-5px, -8px) ;
  }
}