button {
    display: flex;
    background: none;
    border: 2px solid;
    border-radius: 8px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    line-height: 1;
    padding: 1em 2em;
    color: #D00D28;
    transition: 0.25s;
    align-items: center;
  }


button:hover,
button:focus {
  box-shadow: inset 0 0 0 2em var(--hover);
  border-color: var(--hover);
  color: white;
  background: #D00D28;
}

@media screen and (max-width: 576px) {
  button {
    line-height: 2;
  }
}